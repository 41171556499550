module.exports = {
  siteTitle: 'AMEY.iNVENT | Social Innovator',
  siteDescription:
    'This is Amey, a social innovator based in Pune, India who enjoys building things that impact the community. He developes exceptional products and deploy those in the community around.!',
  siteKeywords:
    'Amey Nerkar, AMEY.iNVENT, amey.invent, ameyinvent, social innovator, Machine Learning, web developer, javascript, amey nerkar, qwerpoiu',
  siteUrl: 'https://ameyinvent.tech',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-162045600-1',
  googleVerification: 'sxqlTWfEFE0AQll20yZPCtiyH4KDQbe7iAl5iBvyDqo',
  name: 'Amey Nerkar',
  location: 'Pune India',
  email: 'amey.invent@gmail.com',
  github: 'https://github.com/ameyinvent',
  twitterHandle: '@ameyinvent',
  socialMedia: [
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/ameyinvent',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/ameyinvent',
    },
    {
      name: 'Blog',
      url: 'https://medium.com/@amey.invent',
    },
    {
      name: 'GitHub',
      url: 'https://github.com/ameyinvent',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/amey.invent',
    },
    
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Highlights',
      url: '/#projects',
    },
    {
      name: 'Blogs',
      url: '/pensieve',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
    
  ],

  navHeight: 100,
  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
