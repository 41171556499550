import React from 'react';

const IconBlog = () => (
    
    <svg xmlns="http://www.w3.org/2000/svg" role="img" height="500" width="500" viewBox="0 0 500 500">
        <title>Blog</title>
        <path
         d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-386.892,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Zm-19.502,137.321l-99.495,0l-70.926,176.926l-80.672,-176.926l-104.37,0l0,6.049l33.614,40.493c3.301,2.974 4.985,7.343 4.537,11.762l0,159.114c0.978,5.739 -0.83,11.603 -4.872,15.794l-37.816,45.868l0,6.048l107.227,0l0,-6.048l-37.816,-45.868c-4.073,-4.182 -5.994,-10.008 -5.21,-15.794l0,-137.607l94.119,205.317l10.924,0l80.84,-205.317l0,163.651c0,4.367 0,5.208 -2.858,8.063l-29.075,28.229l0,6.048l141.175,0l0,-6.048l-28.066,-27.556c-2.478,-1.888 -3.707,-4.993 -3.194,-8.065l0,-202.463c-0.513,-3.073 0.716,-6.178 3.194,-8.066l28.74,-27.555l0,-6.049Z"/>
    </svg>
);

export default IconBlog;




