import React from 'react';

const IconFolder = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 60 60">
    <title>Folder</title>
    <path
      d="M31.25 38.125C30.3125 38.125 29.6875 37.8125 28.75 37.5L25 35.9375L21.25 37.5C20.625 37.8125 19.6875 38.125 18.75 38.125C18.125 38.125 17.8125 38.125 17.1875 37.8125L15.625 50L25 43.75L34.375 50L32.5 37.8125C32.1875 38.125 31.5625 38.125 31.25 38.125Z" fill="white"/>
      <path d="M40.3125 18.4375C40 17.8125 40 16.875 40.3125 16.25L42.1875 12.5C42.8125 11.25 42.1875 9.6875 40.625 9.0625L36.5625 7.5C35.9375 7.1875 35.3125 6.5625 35 5.9375L33.4375 1.875C33.125 0.625 32.1875 0 31.25 0C30.9375 0 30.3125 0 30 0.3125L25.9375 2.1875H25C24.6875 2.1875 24.375 2.1875 24.0625 1.875L20 0.3125C19.6875 0 19.0625 0 18.75 0C17.8125 0 16.875 0.625 16.25 1.5625L14.6875 5.9375C14.6875 6.5625 14.0625 7.1875 13.4375 7.5L9.0625 9.0625C7.8125 9.375 7.1875 10.9375 7.8125 12.5L9.6875 16.5625C10 17.1875 10 18.125 9.6875 18.75L7.8125 22.5C7.1875 23.75 7.8125 25.3125 9.375 25.9375L13.4375 27.5C14.0625 27.8125 14.6875 28.4375 15 29.0625L16.5625 33.125C16.875 34.375 17.8125 35 18.75 35C19.0625 35 19.375 35 19.6875 34.6875L23.75 32.8125C24.0625 32.8125 24.375 32.5 24.6875 32.5C25 32.5 25.3125 32.5 25.625 32.8125L29.6875 34.6875C30 35 30.3125 35 30.625 35C31.5625 35 32.5 34.375 33.125 33.4375L34.6875 29.375C35 28.75 35.625 28.125 36.25 27.8125L40.3125 26.25C41.5625 25.625 42.5 24.0625 41.875 22.8125L40.3125 18.4375V18.4375ZM25 30C18.125 30 12.5 24.375 12.5 17.5C12.5 10.625 18.125 5 25 5C31.875 5 37.5 10.625 37.5 17.5C37.5 24.375 31.875 30 25 30Z" fill="white"/>
      <path d="M34.375 17.5C34.375 19.9864 33.3873 22.371 31.6291 24.1291C29.871 25.8873 27.4864 26.875 25 26.875C22.5136 26.875 20.129 25.8873 18.3709 24.1291C16.6127 22.371 15.625 19.9864 15.625 17.5C15.625 15.0136 16.6127 12.629 18.3709 10.8709C20.129 9.11272 22.5136 8.125 25 8.125C27.4864 8.125 29.871 9.11272 31.6291 10.8709C33.3873 12.629 34.375 15.0136 34.375 17.5V17.5Z" fill="white"/>
      
  </svg>
);

export default IconFolder;
