import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
      <path d="M40.4453 16.2539L51.0273 58.7227L59.3945 59.9531L39.6016 68.3906L46.3516 59.8125L42.4141 50.0039L24.2734 50.6016L20.582 61.043L27.3672 69.9727L6.34375 61.6758L16.6797 60.6562L19.6328 50.7422H17.9102L14.957 46.9805L20.9336 46.3477L27.2969 25.1133L20.0898 21.3516L40.4453 16.2539ZM25.9258 45.7852L39.9531 44.168L32.7461 26.3438L25.9258 45.7852ZM57.2852 66.3516L62.8047 62.0273L61.6094 37.7695L55.1055 33.5156L70.2227 30.9492L67.5156 59.1445L75.0742 63.8555L57.2852 66.3516ZM70.6094 22.4414C70.6094 23.1445 70.4688 23.8125 70.1875 24.4453C69.9062 25.0547 69.5195 25.6055 69.0273 26.0977C68.5352 26.5664 67.9727 26.9414 67.3398 27.2227C66.707 27.4805 66.0391 27.6094 65.3359 27.6094C64.5859 27.6094 63.8828 27.4805 63.2266 27.2227C62.5938 26.9414 62.043 26.5664 61.5742 26.0977C61.1055 25.6055 60.7305 25.0547 60.4492 24.4453C60.168 23.8125 60.0273 23.1445 60.0273 22.4414C60.0273 21.7148 60.168 21.0469 60.4492 20.4375C60.7305 19.8047 61.1055 19.2539 61.5742 18.7852C62.043 18.3164 62.5938 17.9531 63.2266 17.6953C63.8828 17.4141 64.5859 17.2734 65.3359 17.2734C66.0391 17.2734 66.707 17.4141 67.3398 17.6953C67.9727 17.9531 68.5352 18.3164 69.0273 18.7852C69.5195 19.2539 69.9062 19.8047 70.1875 20.4375C70.4688 21.0469 70.6094 21.7148 70.6094 22.4414Z" fill="#FFFfFf"/>


        <polygon
          id="Shape"
          stroke="#ABB3FF"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="0 0 0 84 84 84 84 0"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
