import React from 'react';

const IconTED = () => (
    
    <svg xmlns="http://www.w3.org/2000/svg" role="img" height="100px" width="350px" version="1.1" viewBox="0 0 350 100">
        <title>TED</title>
         <path d="m32.678 30.831h-32.678l-1.4211e-14 -29.981h101.56v29.981h-32.685v87.469h-36.197v-87.469zm74.502-29.981h98.75v29.981h-62.55v14.462h62.55v28.212h-62.55v14.814h62.55v29.981h-98.75v-117.45zm104.72 0h59.39c39.01 0 52.71 28.923 52.71 58.551 0 35.975-18.98 58.899-59.74 58.899h-52.36v-117.45zm36.2 87.469h14.05c22.49 0 25.66-18.337 25.66-29.27 0-7.41-2.46-27.865-28.47-27.865h-11.6l0.36 57.135z"/>
    </svg>

);

export default IconTED;

